import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Adwokat Kamil Piwowar- Kancelaria Adwokacka w Lublinie', // e.g: 'Name | Developer'
  lang: 'pl', // e.g: en, es, fr, jp
  description:
    'Profesjonalna pomoc prawna dla przedsiębiorców oraz osób fizycznych w Lublinie. Obszary dzialalności: prawo karne, cywilne, gospodarcze etc.', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '"Obrońca nie jest narzędziem sądu, lecz czynnikiem ustalenia prawdy"',
  mail: 'kamil.piwowar@adwokat-piwowar.pl',
  phone: '+ 48 507 574 027 ',
  img: 'foto1.jpeg',
  imgLogo: 'logo.png',
  subtitle: ' Antoine-Gaspard Boucher d Argis',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img1: 'logoTOT.png',
  img1LinkAdress: 'http://timeontime.pl',
  img2: 'logoBpsa.png',
  img2LinkAdress: 'http://bpsa-center.pl',
  paragraphOne: 'Kancelaria Adwokacka',
  paragraphTwo: 'ul. Relaksowa 18a/1',
  paragraphThree: '20-819 Lublin ',
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: '<logo className="jpg"></logo>',

    title: 'Prawo cywilne',
    img: 'cywilne.png',
    info: 'test',
    info2: 'test',
    url: 'mailto:kamil.piwowar@adwokat-piwowar.pl',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    title: 'Sprawy karne',
    img: 'karne.png',
    info: 'test1',
    info2:
      'Reprezentujemy naszych Klientów w postępowaniu przygotowawczym, sądowym oraz wykonawczym. Kancelaria zapewnia obronę na każdym etapie sprawy, w tym w szczególności...',
    url: 'mailto:kamil.piwowar@adwokat-piwowar.pl',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    title: 'Prawo spółek',
    img: 'spolek.png',
    info: '',
    info2: '',
    url: 'mailto:kamil.piwowar@adwokat-piwowar.pl',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    title: 'Prawo rodzinne',
    img: 'rodzinne.png',
    info: '',
    info2: '',
    url: 'mailto:kamil.piwowar@adwokat-piwowar.pl',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    title: 'Prawo pracy',
    img: 'pracy.png',
    info: 'Reprezentujemy też pracodawców w sporach z nieuczciwymi pracownikami.',
    info2: '',
    url: 'mailto:kamil.piwowar@adwokat-piwowar.pl',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    title: 'Dochodzenie odszkodowań',
    img: 'odszkodowanie.png',
    info: '',
    info2: '',
    url: 'mailto:kamil.piwowar@adwokat-piwowar.pl',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Potrzebujesz pomocy? Zadzwon lub napisz do nas!',
  btn: 'Napisz',
  email: 'kamil.piwowar@adwokat-piwowar.pl',
};

// FOOTER DATA
// export const footerData = {
//   networks: [
//     {
//       id: nanoid(),
//       name: 'twitter',
//       url: '',
//     },
//     {
//       id: nanoid(),
//       name: 'codepen',
//       url: '',
//     },
//     {
//       id: nanoid(),
//       name: 'linkedin',
//       url: '',
//     },
//     {
//       id: nanoid(),
//       name: 'github',
//       url: '',
//     },
//   ],
// };

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
